<template lang="html">
  <div
    class="modal fade"
    id="lightboxModal"
    tabindex="-1"
    aria-labelledby="lightboxModalLabel"
    aria-hidden="true"
    v-if="typeof giftbox.image !== 'undefined'"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="lightboxModalLabel"
            v-html="giftbox.name"
          ></h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div v-if="typeof giftbox.image != 'undefined'">
              <img
                v-if="giftbox.image.path !== null"
                :src="
                  'https://sendcloudholidaygifts.com/db/storage/uploads/' +
                  giftbox.image.path
                "
                class="card-img-top"
                alt="..."
              />
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightBoxModal",
  props: ["giftbox"],
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="css" scoped></style>
