import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import GiftboxesView from "../views/GiftboxesView.vue";
import CheckoutView from "../views/CheckoutView.vue";
import ThankYouView from "../views/ThankYouView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/giftboxes",
    name: "Giftboxes",
    component: GiftboxesView,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutView,
  },
  {
    path: "/thankyou",
    name: "ThankYouView",
    component: ThankYouView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
