<template>
  <div class="screen">
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

.transparentbox {
  background-color: #900001;
  border-radius: 25px !important;
  padding-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  background: rgb(144, 0, 1);
  background: rgba(144, 0, 1, 0.5);
}
.padding-top {
  padding-top: 50px;
}
p {
  font-size: 1.2rem;
}
body {
  background: url("~@/assets/bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
  height: 100%;
}
.headline {
  color: #c34128;
}
input::placeholder {
  color: #f4ad4a !important;
}
.responsive-img {
  width: 100%;
  height: auto;
}
</style>
