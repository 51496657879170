import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    employee: null,
    texts: null,
    giftboxes: [],
    giftboxtotals: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getEmployee(state) {
      return state.employee;
    },
    getTexts(state) {
      return state.texts;
    },
    getGiftboxes(state) {
      return state.giftboxes;
    },
    getGiftboxTotals(state) {
      return state.giftboxtotals;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setEmployee(state, employee) {
      state.employee = employee;
    },
    setTexts(state, texts) {
      state.texts = texts;
    },
    setGiftboxes(state, giftboxes) {
      state.giftboxes = giftboxes;
    },
    setGiftboxTotals(state, giftboxtotals) {
      state.giftboxtotals = giftboxtotals;
    },
  },
  actions: {
    saveEmployee(state, data) {
      state.commit("setLoading", true);
      data._state = 1;
      let token = process.env.VUE_APP_SAVEEMPLOYEETOKEN;
      axios
        .post(
          process.env.VUE_APP_COCKPITURL + "/content/item/employees",
          {
            data: data,
          },
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setEmployee", response.data);
          state.dispatch("fetchGiftboxTotals");
          return response;
        })
        .catch((err) => console.log(err));
    },
    getEmployee(state, data) {
      state.commit("setLoading", true);
      axios
        .get("https://sendcloudholidaygifts.com/get_employee/", {
          params: {
            email: data.email,
            code: data.code.toUpperCase(),
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setEmployee", response.data);
          router.push("/giftboxes");
        })
        .catch((err) => console.log(err));
    },
    fetchGiftboxTotals(state) {
      state.commit("setLoading", true);
      axios
        .get("https://sendcloudholidaygifts.com/get_totals/", {})
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setGiftboxTotals", response.data);
        })
        .catch((err) => console.log(err));
    },
    fetchGiftBoxes(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_GIFTBOXTOKEN;
      axios
        .get(process.env.VUE_APP_COCKPITURL + "/content/items/giftboxes", {
          params: {},
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setGiftboxes", response.data);
        })
        .catch((err) => console.log(err));
    },
    fetchAppTexts(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_TEXTTOKEN;
      axios
        .get(process.env.VUE_APP_COCKPITURL + "/content/item/app_text", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setTexts", response.data);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
