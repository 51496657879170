<template>
  <GiftboxDetail :giftbox="modal_giftbox" />
  <LightBoxModal :giftbox="modal_giftbox" />
  <div class="container">
    <div class="row">
      <div
        class="col-lg-10 offset-lg-1 col-md-12 offset-md-0 transparentbox mt-5 mb-5 padding-top"
      >
        <h1 class="text-light text-center">Hi {{ getEmployee.first_name }}!</h1>
        <!-- {{ getGiftboxes }} -->
        <span v-html="getEmployeeIntro" class="text-light text-center"></span>
        <div
          class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-4"
        >
          <div class="col" v-for="giftbox in getGiftboxes" v-bind:key="giftbox">
            <div class="card h-100">
              <img
                v-if="giftbox.image.path !== null"
                :src="
                  'https://sendcloudholidaygifts.com/db/storage/uploads/' +
                  giftbox.image.path
                "
                class="card-img-top"
                alt="..."
              />
              <div class="card-img-overlay" style="text-align: right">
                <h4>
                  <span class="badge bg-primary">
                    Available
                    {{ getGiftboxTotal(giftbox) }}
                  </span>
                </h4>
              </div>
              <div class="card-img-overlay">
                <button
                  type="button"
                  class="btn btn-light btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#lightboxModal"
                  @click="populateModal(giftbox)"
                  style="z-index: 999"
                >
                  <i class="bi bi-card-image"></i>
                </button>
              </div>
              <div class="card-body">
                <h5 class="card-title text-center headline">
                  {{ giftbox.name }}
                </h5>
                <div class="card-text text-center">
                  <div class="fadetext">
                    <span v-html="giftbox.description"></span>
                  </div>
                </div>
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-primary rounded-pill"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    @click="populateModal(giftbox)"
                  >
                    View Giftbox
                    <span
                      v-if="giftbox.number == getEmployee.choice"
                      class="badge text-bg-light"
                    >
                      Selected
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GiftboxDetail from "@/components/GiftboxDetail.vue";
import LightBoxModal from "@/components/LightBoxModal.vue";

export default {
  name: "GiftboxesView",
  created() {
    this.$store.dispatch("fetchGiftboxTotals");
    this.$store.dispatch("fetchGiftBoxes");
    this.populateModal(this.getGiftboxes[0]);
  },
  data: () => ({
    modal_giftbox: {},
  }),
  components: {
    GiftboxDetail,
    LightBoxModal,
  },
  computed: {
    getGiftboxes() {
      return this.$store.getters.getGiftboxes;
    },
    getGiftboxTotals() {
      return this.$store.getters.getGiftboxTotals;
    },
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getEmployeeIntro() {
      let texts = this.$store.getters.getTexts;
      return texts.employee_introduction;
    },
  },
  methods: {
    populateModal(giftbox) {
      this.modal_giftbox = giftbox;
    },
    getGiftboxTotal(giftbox) {
      let totals = this.getGiftboxTotals;
      let quantityLeft = giftbox.quantity;
      if (typeof totals[giftbox.number] != "undefined") {
        quantityLeft = giftbox.quantity - totals[giftbox.number];
      }
      return quantityLeft;
    },
  },
};
</script>

<style>
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.card {
  border: none;
}
.fadetext {
  height: 100px !important;
  overflow: hidden !important;
  -webkit-mask-image: linear-gradient(180deg, #000 0%, transparent);
}
.card-body {
  z-index: 1000;
}
</style>
