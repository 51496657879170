<template>
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div class="col-md-6 offset-md-3 transparentbox padding-top">
          <h2 class="text-light">Login</h2>
          <h4 class="text-light">Use your email address and unique code</h4>
          <input
            v-model="email"
            class="form-control field no-focusborder rounded-pill form-control-lg"
            :class="[isEmail ? '' : 'is-invalid']"
            type="email"
            ref="inputfield"
            placeholder="Email address"
            required
          />
          <!-- {{ validateEmail }} -->
          <input
            v-model="code"
            class="form-control field no-focusborder text-uppercase rounded-pill form-control-lg"
            type="text"
            ref="inputfield"
            placeholder="Code"
            maxlength="5"
            required
          />
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary rounded-pill text-light btn-lg"
              :disabled="isDisabled == true"
              @click="getEmployee()"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  components: {},
  data: () => ({
    email: null,
    code: null,
  }),
  computed: {
    isEmail() {
      if (this.email == null) {
        return true;
      }
      if (this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return true;
        }
      }
      return false;
    },
    isDisabled() {
      if (this.email && this.code) {
        if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) &&
          this.code.length == 5
        ) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  created() {},
  methods: {
    getEmployee() {
      let data = {};
      data.email = this.email;
      data.code = this.code;
      this.$store.dispatch("getEmployee", data);
    },
  },
};
</script>

<style>
/* img {
  padding-bottom: 20px;
} */
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
input {
  margin-bottom: 5px;
  border-radius: 100%;
  text-align: center;
}
</style>
