<template lang="html">
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div class="col-md-8 offset-md-2 transparentbox padding-top">
          <h1 class="text-light">Ho ho ho {{ getEmployee.first_name }},</h1>
          <h2 class="text-light mb-3">
            We hope you enjoy your Christmas giftbox
          </h2>
          <img
            class="responsive-img"
            src="@/assets/giphy.gif"
            alt=""
            style="max-width: 480px"
          />
          <h2 class="text-light mt-3">
            Now all you have to do is <br />
            sit back and wait
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouView",
  data: () => ({}),
  computed: {
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getGiftbox() {
      let giftboxes = this.$store.getters.getGiftboxes;
      let rGiftbox = null;
      giftboxes.forEach((giftbox) => {
        if (this.getEmployee.choice == giftbox.number) {
          rGiftbox = giftbox;
        }
      });
      return rGiftbox;
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped></style>
