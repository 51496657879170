<template lang="html">
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div
          class="col-lg-8 offset-lg-2 col-md-12 offset-md-0 transparentbox mt-5 mb-5 padding-top"
        >
          <!-- <h1 class="text-light">{{ getEmployee.first_name }},</h1> -->
          <h1 class="text-light">Good choice!</h1>
          <h2 class="text-light">
            You chose the "{{ getGiftbox.name }}" giftbox
          </h2>

          <h3 class="text-light">Please check your shipping details below</h3>
          <div class="row">
            <div class="input-group mb-3">
              <input
                v-model="getEmployee.first_name"
                type="text"
                class="form-control form-control-lg rounded-pill-left"
                placeholder="First name"
                aria-label="First name"
                required
              />
              <input
                v-model="getEmployee.last_name"
                type="text"
                class="form-control form-control-lg rounded-pill-right"
                placeholder="Last name"
                aria-label="Last name"
                required
              />
            </div>
          </div>

          <div class="input-group mb-3">
            <input
              v-model="getEmployee.street"
              type="text"
              class="form-control form-control-lg rounded-pill-left"
              placeholder="Street"
              aria-label="Street"
              required
            />
            <input
              v-model="getEmployee.street_number"
              type="text"
              class="form-control form-control-lg rounded-pill-middle"
              placeholder="Street number"
              aria-label="Street number"
              required
            />
            <input
              v-model="getEmployee.street_nr_suffix"
              class="form-control field rounded-pill-right"
              type="text"
              ref="inputfield"
              placeholder="Street no. suffix"
              aria-label="Street no. suffix"
              required
            />
          </div>

          <div class="row">
            <div class="input-group mb-3">
              <input
                v-model="getEmployee.postalcode"
                type="text"
                class="form-control form-control-lg rounded-pill-left"
                placeholder="Postal code"
                aria-label="Postal code"
                required
              />
              <input
                v-model="getEmployee.city"
                type="text"
                class="form-control form-control-lg rounded-pill-right"
                placeholder="Employee city"
                aria-label="Employee city"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="input-group mb-3">
              <input
                v-model="getEmployee.country"
                type="text"
                class="form-control form-control-lg rounded-pill"
                placeholder="Country"
                aria-label="Country"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-lg btn-primary rounded-pill"
                  @click="saveEmployee()"
                  :disabled="!hasValidAddress"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutView",
  data: () => ({}),
  computed: {
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getGiftbox() {
      let giftboxes = this.$store.getters.getGiftboxes;
      let rGiftbox = null;
      giftboxes.forEach((giftbox) => {
        if (this.getEmployee.choice == giftbox.number) {
          rGiftbox = giftbox;
        }
      });
      return rGiftbox;
    },
    hasValidAddress() {
      let employee = this.getEmployee;
      if (
        employee.first_name == "" ||
        employee.last_name == "" ||
        employee.street == "" ||
        employee.street_number == "" ||
        employee.postalcode == "" ||
        employee.city == "" ||
        employee.country == ""
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    saveEmployee() {
      let employee = this.getEmployee;
      this.$store.dispatch("saveEmployee", employee).then((response) => {
        console.log(response);
        this.$router.push("/thankyou");
      });
    },
  },
};
</script>

<style lang="css" scoped>
.rounded-pill-left {
  border-radius: 10rem 0rem 0rem 10rem;
}
.rounded-pill-right {
  border-radius: 0rem 10rem 10rem 0rem;
}
.rounded-pill-middle {
  border-radius: 0rem 0rem 0rem 0rem;
}
</style>
